<template>
  <div class="cahtbot_router" ref="CHATBOT_ROUTER">
    <div class="m-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "CHATBOT"
  // beforeRouteEnter (to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   // { name: 'Commenter.NewCommenter' }
  //   if (to.name === 'chatbot') {
  //     next({ name: 'chatbot.insights' })
  //   } else {
  //     next()
  //   }
  // }
};
</script>

<style scoped lang="scss">
.cahtbot_router {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}
</style>
